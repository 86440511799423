@let data = (data$ | async);
@let exportSettingsMaximize = (exportSettingsMaximize$ | async);
@let templates = data ? data.templates : [];
<div
  class="dca-heading flex items-center justify-between px2 col-12"
  *ngIf="data; else loading"
>
  <div class="h2 flex items-center col-12">
    <button
      mat-icon-button
      aria-label="Close the Configuration"
      matTooltip="Close Template Configuration"
      *ngIf="exportSettingsMaximize"
      (click)="closeMetaDataEditor()"
    >
      <mat-icon aria-label="Open/Hide DCA">close</mat-icon>
    </button>
    <span>Template Configuration</span>
    @if (exportSettingsMaximize) {
      <div class="flex flex-auto"></div>
      <div class="clearfix mxn2 pr2">
        <div class="col px1">
          <mat-button-toggle-group
            name="uiVisibilityRoles"
            aria-label="Visibility Roles"
            [value]="selectedUiRoles"
            (change)="onToggleChange($event.value)"
            multiple
            matTooltip="Change displayed categories"
          >
            <mat-button-toggle *ngFor="let ui of uiVisibilityRoles" [value]="ui.id">
              {{ ui.label }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col px1">
          <button
            mat-icon-button
            color="outline"
            (click)="downloadTemplate()"
            matTooltip="Copy the YAML configuration to create a new template with the current configuration"
          >
            <mat-icon>copy_all</mat-icon>
          </button>
        </div>
      </div>
    }
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

<div class="dca-scrollarea">
  <div class="flex">
    <div *ngIf="exportDcaMaximize$ | async" class="flex">
      <div class="dca-width" *ngIf="data">
        <form>
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Template</mat-label>
            <mat-select
              name="template"
              (selectionChange)="navigateToTemplate($event.value)"
              [value]="data.template?.slug"
              [compareWith]="compareTemplate"
            >
              <mat-option *ngFor="let template of templates" [value]="template">
                {{ template }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <div *ngIf="activeTemplate$ | async; let template; else selectTemplate;" class="m1 p1">
          <b *ngIf="template.title">{{ template.title }}</b>
          <p *ngIf="template.description">
            <i>{{ template.description }}</i>
          </p>
          <div>
            <p>
              The export options will use the manuscript and any author data that was provided to
              export into various formats.
            </p>
            <p>
              To learn more about how to create your own templates, please see the
              <a href="https://docs.sciflow.org/development/tdk/" target="_blank"
                >Template Development Kit</a
              >
            </p>
          </div>
          <div class="flex col-12 mx-auto py1 justify-between">
            <div class="flex">
              <button
                mat-stroked-button
                [disabled]="!canEdit(data.editor.key)"
                class="mt1"
                (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'pagedjs')"
                matTooltip="Paged.js - Print PDF in
            browser"
              >
                PDF (paged.js)
              </button>
              <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="more formats">
                <mat-icon>expand_more</mat-icon>
              </button>
            </div>
            <span>
              <button
                mat-icon-button
                *ngIf="data?.template?.metaData"
                matTooltip="Open Template Configuration"
                (click)="toggleMetaData()"
              >
                <mat-icon>settings</mat-icon>
              </button>
              <button
                mat-icon-button
                (click)="resetMetaData()"
                matTooltip="Reset settings to template defaults"
              >
                <mat-icon>restart_alt</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngIf="data.template?.runners?.includes('xml')"
                  [disabled]="!canEdit(data.editor.key)"
                  (click)="openLens(data.template?.slug, data.editor.key, 'xml')"
                >
                  <b>Lens Viewer</b> (JATS XML)
                </button>
                <button
                  mat-menu-item
                  *ngIf="data.template?.runners?.includes('xml')"
                  [disabled]="!canEdit(data.editor.key)"
                  (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'xml')"
                >
                  <b>XML</b> (JATS XML)
                </button>
                <button
                  mat-menu-item
                  *ngIf="data.template?.runners?.includes('html')"
                  [disabled]="!canEdit(data.editor.key)"
                  (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'html')"
                >
                  <b>HTML</b>
                </button>
                <button
                  mat-menu-item
                  *ngIf="data.template?.runners?.includes('princexml')"
                  [disabled]="!canEdit(data.editor.key)"
                  matTooltip="Export using PrinceXML"
                  (click)="exportWithTemplate(data.template?.slug, data.editor.key, 'princexml')"
                >
                  <b>PrinceXML</b> (PDF)
                </button>

                <mat-divider></mat-divider>
                <button
                  mat-menu-item
                  [disabled]="!canEdit(data.editor.key)"
                  matTooltip="Export Data Snapshot"
                  (click)="exportSnapshot(data.editor.key)"
                >
                  <b>Data Snapshot</b> (ZIP)
                </button>
              </mat-menu>
            </span>
          </div>
        </div>

        <div class="px3">
          @if (isDocumentStateDirty$ |async) {
            <span class="italic"
              >*Document contains unsaved changes, these may not reflect in the export.</span
            >
          }
        </div>
      </div>
    </div>

    <div class="col-12">
      <sfo-export-settings
        [selectedUi]="selectedUiRoles"
        *ngIf="exportSettingsMaximize"
      >
      </sfo-export-settings>
    </div>

    <ng-template #selectTemplate>
      <p class="m2">
        <b>Please select a template above.</b>
      </p>
    </ng-template>
  </div>
</div>

<ng-template #loading>
  <div class="dca-heading flex items-center justify-between px2">
    <div class="h2 flex items-center">
      <span>Loading ...</span>
    </div>
  </div>
</ng-template>
